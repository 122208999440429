<template>
    <div>
        <b-card>
            <b-card-img :src="membership.membership.image" alt="Image" class="rounded-0"></b-card-img>

            <div class="plan-name">
                {{ membership.membership.name }}
            </div>
            <div class="row">
                <div class="col-6 mb-2">
                    Total invested
                </div>
                <div class="col-6 mb-2 text-right">
                    $80/usd
                </div>
                <div class="col-6 mb-2">
                    Total available
                </div>
                <div class="col-6 mb-2 text-right">
                    $80/usd
                </div>
                <div class="col-6 mb-2">
                    Total win
                </div>
                <div class="col-6 mb-2 text-right">
                    $80/usd
                </div>
            </div>
            <div class="mt-4">
                <b-progress value="60" max="100" class="mb-3" height="10px"></b-progress>
                <b-button class="w-100" variant="primary" disabled>
                    Pending to pay: ${{ membership.balance }}
                </b-button>
            </div>
        </b-card>
    </div>
</template>
<script>
import Purchase from './Purchase.vue'
import moment from 'moment'
export default {
    props: ['membership'],
    components: {
        Purchase
    },
    data () {
        return {
            modalPurchase: false
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format('DD-MM-Y')
        }
    }
}
</script>
<style scoped>
    .plan-name{
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
    }
    .plan-price{
        font-weight: 300;
        font-size: 36px;
        line-height: 40px;
    }
    .plan-price span {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
    }
</style>
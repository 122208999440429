<template>
  <div>
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="mt-4">
          <div class="text-left mb-3 text-white">
            <h2>Select time</h2>
          </div>
          <div class="row">
            <div
              class="col-12 col-sm-6 col-md-4 mb-3 col-lg-3 text-center mb-2 click"
              @click="selectMontht(1, 50000)"
            >
              <b-card
                class="h-100"
                :class="form.months == '1' ? 'pay-active' : ''"
              >
                <div class="text-center">
                  <img src="@/assets/icons/1.png" class="w-50 click" alt="" />
                  <h3 class="mt-3 text-primary">One Month</h3>
                  <h6>5% Profit</h6>
                </div>
              </b-card>
            </div>
            <div
              class="col-12 col-sm-6 col-md-4 mb-3 col-lg-3 text-center mb-2 click"
              @click="form.months = '3'"
            >
              <b-card
                class="h-100"
                :class="form.months == '3' ? 'pay-active' : ''"
              >
                <div class="text-center">
                  <img src="@/assets/icons/3.png" class="w-50 click" alt="" />
                  <h3 class="mt-3 text-primary">Three Months</h3>
                  <h6>9% Profit</h6>
                </div>
              </b-card>
            </div>
            <div
              class="col-12 col-sm-6 col-md-4 mb-3 col-lg-3 text-center mb-2 click"
              @click="form.months = '6'"
            >
              <b-card
                class="h-100"
                :class="form.months == '6' ? 'pay-active' : ''"
              >
                <div class="text-center">
                  <img src="@/assets/icons/6.png" class="w-50 click" alt="" />
                  <h3 class="mt-3 text-primary">Six Months</h3>
                  <h6>18% Profit</h6>
                </div>
              </b-card>
            </div>
            <div
              class="col-12 col-sm-12 col-md-4 mb-3 col-lg-3 text-center mb-2 click"
              @click="form.months = '12'"
            >
              <b-card
                class="h-100"
                :class="form.months == '12' ? 'pay-active' : ''"
              >
                <div class="text-center">
                  <img src="@/assets/icons/12.png" class="w-50 click" alt="" />
                  <h3 class="mt-3 text-primary">Twelve Months</h3>
                  <h6>36% Profit</h6>
                </div>
              </b-card>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <div class="text-left mb-3 text-white">
            <h2>Select payment method</h2>
          </div>
          <div class="row">
            <div
              class="col-6 col-sm-6 col-lg-3 mb-3"
              @click="form.payment_method = 'usdtt'"
            >
              <b-card
                class="h-100"
                :class="form.payment_method == 'usdtt' ? 'pay-active' : ''"
              >
                <div class="text-center">
                  <img
                    src="@/assets/icons/usdt.png"
                    class="w-70 click"
                    alt=""
                  />
                  <h4 class="mt-3">USDT</h4>
                </div>
              </b-card>
            </div>
            <!-- <div
              class="col-6 col-sm-6 col-lg-3 mb-3"
              @click="form.payment_method = 'bsc'"
            >
              <b-card
                class="h-100"
                :class="form.payment_method == 'bsc' ? 'pay-active' : ''"
              >
                <div class="text-center">
                  <img src="@/assets/icons/bnb.png" class="w-70 click" alt="" />
                  <h4 class="mt-3">BNB</h4>
                </div>
              </b-card>
            </div>
            <div
              class="col-6 col-sm-6 col-lg-3 mb-3"
              @click="form.payment_method = 'btc'"
            >
              <b-card
                class="h-100"
                :class="form.payment_method == 'btc' ? 'pay-active' : ''"
              >
                <div class="text-center">
                  <img src="@/assets/icons/btc.png" class="w-70 click" alt="" />
                  <h4 class="mt-3">BTC</h4>
                </div>
              </b-card>
            </div>
            <div
              class="col-6 col-sm-6 col-lg-3 mb-3"
              @click="form.payment_method = 'ltc'"
            >
              <b-card
                class="h-100"
                :class="form.payment_method == 'ltc' ? 'pay-active' : ''"
              >
                <div class="text-center">
                  <img src="@/assets/icons/ltc.png" class="w-70 click" alt="" />
                  <h4 class="mt-3">LTC</h4>
                </div>
              </b-card>
            </div>
            <div
              class="col-6 col-sm-6 col-lg-3 mb-3"
              @click="form.payment_method = 'usdte'"
            >
              <b-card
                class="h-100"
                :class="form.payment_method == 'usdte' ? 'pay-active' : ''"
              >
                <div class="text-center">
                  <img
                    src="@/assets/icons/usdt.png"
                    class="w-70 click"
                    alt=""
                  />
                  <h4 class="mt-3">USDT (ERC20)</h4>
                </div>
              </b-card>
            </div>
            <div
              class="col-6 col-sm-6 col-lg-3 mb-3"
              @click="form.payment_method = 'eth'"
            >
              <b-card
                class="h-100"
                :class="form.payment_method == 'eth' ? 'pay-active' : ''"
              >
                <div class="text-center">
                  <img src="@/assets/icons/eth.png" class="w-70 click" alt="" />
                  <h4 class="mt-3">ETH</h4>
                </div>
              </b-card>
            </div> -->
            <div class="col-6 col-sm-6 col-lg-3 mb-3" @click="form.payment_method = 'bta'">
                <b-card class="h-100" :class="form.payment_method == 'bta' ? 'pay-active' : ''">
                    <div class="text-center">
                        <img  src="@/assets/icons/bta.png" class="w-70 click" alt="">
                        <h4 class="mt-3">
                            B7T
                        </h4>
                    </div>
                </b-card>
            </div>
          </div>
        </div>
        <div class="text-right mt-4">
          <b-button
            variant="outline-primary"
            size="lg"
            class="mr-3"
            @click="$emit('close')"
          >
            Cancel
          </b-button>
          <ButtonAction
            lg="true"
            :disabled="form.payment_method == null || form.months == null"
            :loading="loading"
            loadingTx="Creating..."
            title="Confirm purchase"
            @click="onSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["id", "price"],
  data() {
    return {
      form: {
        title: null,
        price: null,
        payment_method: null,
        model: "Membership",
        model_id: null,
      },
      loading: false,
    };
  },
  created() {
    let membership = this.memberships.filter(
      (element) => element.id == this.id
    );

    if (membership) {
      this.form.title = membership[0].name;
      this.form.price = this.price;
      this.form.model_id = membership[0].id;
    } else {
      openNotification("Uknown Error", "error");
    }
  },
  methods: {
    ...mapActions("cart", ["storeCart"]),
    onSubmit() {
      this.loading = true;
      this.storeCart(this.form).then((response) => {
        this.loading = false;
        openNotification();
        this.$emit("close");
        this.$router.push({ name: "Cart", params: { id: response.id } });
      });
    },
    selectMontht(months, min) {
      if (this.form.price >= min) {
        this.form.months = months;
      }
    },
  },
  computed: {
    ...mapState("membership", ["memberships"]),
  },
};
</script>
<style scoped>
.pay-active {
  border: 5px solid #e3c87a !important;
  border-radius: 20px !important;
}
.pay-active h4 {
  color: #e3c87a !important;
}
</style>
